import React, { useRef, useState, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { matchPath } from 'react-router';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import axios from 'axios';

import './css/library/bootstrap.min.css';
import './css/banner.css';
import './css/body.css';
import './css/carousel.css';
import './css/debug.css';
import './css/footer.css';
import './css/form.css';
import './css/navbar.css';
import './css/panel.css';
import './css/status.css';
import './css/whatsapp.css';

import { PageDisclaimer, PageHomepage, PagePrivacy } from './components/Page';

import { SectionNavigation } from './components/Section';

axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
axios.defaults.xsrfCookieName = "csrftoken";

export default function App()
{
    // Debug
    const debug = useRef(false);

    // IP address
    const ip_address = useRef('https://thissen.ai');
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development')
    {
        ip_address.current = 'http://localhost:8006';

        debug.current = true;
        
        console.log('IP: '+ip_address.current);
    }
    
    // Translations
    const [translations_nl, translations_nl_set] = useState([]);
    useEffect(() => {
        const get = async() => {
            try
            {
                const response = await axios.get(ip_address.current+'/backend/autoglaslimburg/api/translations');
                // <str> key
                // <str> language_nl

                // Build dictionary
                let dict = {};
                for (let i = 0; i < response.data.length; i++)
                {
                    dict[response.data[i][0]] = response.data[i][1];
                }

                translations_nl_set(dict);

                if (debug.current === true)
                    console.log(dict);
            }

            catch (error)
            {
                console.log(error);
            }
        }

        get();
    }, [debug]);

    // Options
    const [options, options_set] = useState(undefined)
    useEffect(() => {
        const get = async() => {
            try
            {
                const response = await axios.get(ip_address.current+'/backend/autoglaslimburg/api/options');
                // <str> whatsapp
                // <date> vacation

                options_set(response.data);

                if (debug.current === true)
                    console.log(response.data);
            }

            catch (error)
            {
                console.log(error);
            }
        }

        get();
    }, [debug]);

    // SEO
    const [seo_data, seo_set_data] = useState([]);
    useEffect(() => {
        const get = async() => {
            try
            {
                const response = await axios.get(ip_address.current+'/backend/autoglaslimburg/api/seo');
                // <str> url
                // <str> meta_title
                // <str> meta_description

                seo_set_data(response.data);
            
                if (debug.current === true)
                    console.log(response.data);
            }

            catch (error)
            {
                console.log(error);
            }
        }
        
        get();
    }, []);

    const [meta_title, meta_set_title] = useState('');
    const [meta_description, meta_set_description] = useState('');

    function SEO(props)
    {
        const location = useLocation();

        useEffect(() => {
            if (props.seo_data !== [])
            {
                for (let i = 0; i < props.seo_data.length; i++)
                {
                    let match = matchPath({path:props.seo_data[i][0], strict:true}, location['pathname']);
                    if (match !== null)
                    {
                        meta_set_title(props.seo_data[i][1]);
                        meta_set_description(props.seo_data[i][2]);

                        if (props.debug === true)
                        {
                            console.log('Meta title: '+props.seo_data[i][1]);
                            console.log('Meta description: '+props.seo_data[i][2]);
                        }

                        break;
                    }
                }
            }
        }, [props.debug, props.seo_data, location]);
        
        return null;
    }

    // Upload images
    const upload_images = useRef('https://autoglaslimburg.nl/uploads/images');
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development')
    {
        upload_images.current = 'http://localhost:8006/backend/autoglaslimburg/uploads/images';

        if (debug.current === true)
        {
            console.log('Upload folder: '+upload_images.current);
        }
    }

    // Scroll to top
    function ScrollToTop()
    {
        const location = useLocation();

        useEffect(() => {
            window.scrollTo(0, 0);
        }, [location]);
        
        return null;
    }

    // Whatsapp
    function Whatsapp(props)
    {
        return (
            <React.Fragment>
                { props.options !== undefined &&
                    <div className="whatsapp-container">
                        <a aria-label="Chat on WhatsApp" href={props.options[1]} target="_blank" rel="noreferrer"><img alt="Chat on WhatsApp" src="/image/icon_whatsapp.svg" height="48px"/></a>
                    </div>
                }
            </React.Fragment>
        );
    }

    // Render
    return (
        <HelmetProvider>
            <Helmet>
                <title>{meta_title}</title>
                <meta name="description" content={meta_description} />
            </Helmet>
            <Router>
                <SEO
                    debug={debug.current}
                    seo_data={seo_data} />
                
                <ScrollToTop />

                <SectionNavigation
                    options={options}
                    translations={translations_nl} />

                <Whatsapp
                    options={options} />
                
                <Routes>
                    { /* Disclaimer */ }
                    <Route
                        exact
                        path="/vrijwaring"
                        element={
                            <PageDisclaimer
                                debug={debug.current}
                                ip_address={ip_address.current}
                                options={options}
                                translations={translations_nl} />
                        } />

                    { /* Homepage */ }
                    <Route
                        exact
                        path="/"
                        element={
                            <PageHomepage
                                debug={debug.current}
                                ip_address={ip_address.current}
                                options={options}
                                translations={translations_nl}
                                upload_images={upload_images.current} />
                        } />

                    { /* Privacy */ }
                    <Route
                        exact
                        path="/privacyverklaring"
                        element={
                            <PagePrivacy
                                debug={debug.current}
                                ip_address={ip_address.current}
                                options={options}
                                translations={translations_nl} />
                        } />

                    { /* Redirect on not found */ }
                    <Route
                        path="*"
                        element={
                            <Navigate
                                to="/" />
                        } />
                </Routes>
            </Router>
        </HelmetProvider>
    );
}

const app = ReactDOM.createRoot(document.getElementById('app'));
app.render(
    <App />
);