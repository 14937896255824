import React from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import DOMPurify from 'dompurify';

// Props: poster (jpg), video (mp4), title, text, buttons (optional), translations
function ElementBanner(props)
{
    let buttons = [];
    if (props.buttons !== null)
    {
        let key = 1;
        for (let i = 0; i < props.buttons.length; i++)
        {
            if (props.buttons[i]['type'] === 'link')
            {
                buttons.push(<a key={key} href={props.buttons[i]['to']} target="_blank" rel="noreferrer" className={props.buttons[i]['class']}>{props.buttons[i]['text']}<span className="banner-link-external-icon"></span></a>);
                key++;
            }

            if (props.buttons[i]['type'] === 'hash')
            {
                buttons.push(<HashLink key={key} smooth to={'#'+props.buttons[i]['to']} className={props.buttons[i]['class']}>{props.buttons[i]['text']}</HashLink>);
                key++
            }

            if (i === 0)
            {
                buttons.push(<span key={i} className="banner-button-splittext">{props.translations['banner_or']}</span>);
                key++;
            }
        }
    }

    return (
        <section className="banner-section">
            <video className={'banner-video '+props.banner} autoPlay loop muted playsInline poster={props.poster}>
            <source src={props.video} type="video/mp4" />
            </video>

            <div className="banner-darken">
                <div className="banner-overlay">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-lg-9 col-xl-8 col-xxl-7">
                                <div className="banner-center">

                                    { /* XS, SM */ }
                                    <div className="d-block d-md-none">
                                        <h1 className="banner-title-mobile" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(props.title)}}></h1>

                                        <p className="banner-text">{props.text}</p>

                                        {buttons}

                                         <div className="banner-reviews-mobile">
                                            <iframe src="https://www.klantenvertellen.nl/retrieve-widget.html?color=white&amp;allowTransparency=true&amp;button=false&amp;lang=nl&amp;tenantId=99&amp;locationId=1060658" width="270" height="60" frameBorder="0" title="KlantenVertellen mobiel"></iframe>
                                        </div>
                                    </div>

                                    { /* MD, LG */ }
                                    <div className="d-none d-md-block d-xl-none">
                                        <div className="banner-logo-tablet"></div>

                                        <div className="banner-logo-text-tablet">
                                            <h1 className="banner-title-tablet" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(props.title)}}></h1>
                                            <p className="banner-text">{props.text}</p>

                                            {buttons}
                                        
                                            <div className="banner-reviews-mobile d-block d-lg-none">
                                                <iframe src="https://www.klantenvertellen.nl/retrieve-widget.html?color=white&amp;allowTransparency=true&amp;button=false&amp;lang=nl&amp;tenantId=99&amp;locationId=1060658" width="270" height="60" frameBorder="0" title="KlantenVertellen desktop"></iframe>
                                            </div>
                                        </div>
                                    </div>

                                    { /* XL, XXL */ }
                                    <div className="d-none d-xl-block">
                                        <div className="banner-logo-desktop"></div>

                                        <div className="banner-logo-text-desktop">
                                            <h1 className="banner-title-desktop" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(props.title)}}></h1>
                                            
                                            <p className="banner-text">{props.text}</p>

                                            {buttons}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

// Props: to, text
function ElementContentLink(props)
{
    return (
        <Link to={props.to} className="body-link">{props.text}</Link>
    );
}

// Props: none
function ElementDebugResponsive()
{
    return (
        <div className="debug-responsive-container">
            <div className="d-block d-sm-none">
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-none"></div>
                <div className="debug-responsive-none"></div>
                <div className="debug-responsive-none"></div>
                <div className="debug-responsive-none"></div>
                <div className="debug-responsive-none"></div>
                <span className="debug-responsive-text">xs</span>
                <span className="debug-responsive-text-link"><a href="http://localhost:8006/backend/autoglaslimburg/admin" target="_blank" rel="noopener noreferrer">backend</a></span>
            </div>
            <div className="d-none d-sm-block d-md-none">
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-none"></div>
                <div className="debug-responsive-none"></div>
                <div className="debug-responsive-none"></div>
                <div className="debug-responsive-none"></div>
                <span className="debug-responsive-text">sm</span>
                <span className="debug-responsive-text-link"><a href="http://localhost:8006/backend/autoglaslimburg/admin" target="_blank" rel="noopener noreferrer">backend</a></span>
            </div>
            <div className="d-none d-md-block d-lg-none">
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-none"></div>
                <div className="debug-responsive-none"></div>
                <div className="debug-responsive-none"></div>
                <span className="debug-responsive-text">md</span>
                <span className="debug-responsive-text-link"><a href="http://localhost:8006/backend/autoglaslimburg/admin" target="_blank" rel="noopener noreferrer">backend</a></span>
            </div>
            <div className="d-none d-lg-block d-xl-none">
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-none"></div>
                <div className="debug-responsive-none"></div>
                <span className="debug-responsive-text">lg</span>
                <span className="debug-responsive-text-link"><a href="http://localhost:8006/backend/autoglaslimburg/admin" target="_blank" rel="noopener noreferrer">backend</a></span>
            </div>
            <div className="d-none d-xl-block d-xxl-none">
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-none"></div>
                <span className="debug-responsive-text">xl</span>
                <span className="debug-responsive-text-link"><a href="http://localhost:8006/backend/autoglaslimburg/admin" target="_blank" rel="noopener noreferrer">backend</a></span>
            </div>
            <div className="d-none d-xxl-block">
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-block"></div>
                <span className="debug-responsive-text">xxl</span>
                <span className="debug-responsive-text-link"><a href="http://localhost:8006/backend/autoglaslimburg/admin" target="_blank" rel="noopener noreferrer">backend</a></span>
            </div>
        </div>
    );
}

// Props: to, text
function ElementFooterLink(props)
{
    return (
        <Link to={props.to} className="footer-link">{props.text}</Link>
    );
}

// Props: text
function ElementHeader(props)
{
    return (
        <React.Fragment>
            <div className="d-block d-md-none">
                <h2 className="panel-header-text-mobile">{props.text}</h2>
            </div>
            <div className="d-none d-md-block">
                <h2 className="panel-header-text-desktop">{props.text}</h2>
            </div>
        </React.Fragment>
    );
}

// Props: text
function ElementHeaderLarge(props)
{
    return (
        <React.Fragment>
            { /* XS, SM */ }
            <div className="d-block d-md-none">
                <h2 className="panel-header-text-large-mobile" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(props.text)}}></h2>
            </div>

            { /* MD, LG */ }
            <div className="d-none d-md-block d-xl-none">
                <h2 className="panel-header-text-large-tablet" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(props.text)}}></h2>
            </div>

            { /* XL, XXL */ }
            <div className="d-none d-xl-block">
                <h2 className="panel-header-text-large-desktop" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(props.text)}}></h2>
            </div>
        </React.Fragment>
    );
}

// Props: title, text, image
function ElementPanelContentContain(props)
{
    return (
        <React.Fragment>
            { /* XS, SM */ }
            <div className="col-12 d-block d-md-none">
                <div className="panel-content-contain-mobile">
                    <ElementHeader text={props.title} />
                    <p className="panel-content-text-black" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(props.text)}}></p>
                    <div className="panel-content-image-contain" style={{backgroundImage: 'url('+props.image+')'}}></div>
                </div>
            </div>

            { /* MD */ }
            <div className="col-6 d-none d-md-block d-lg-none">
                <div className="panel-content-contain-tablet">
                    <ElementHeader text={props.title} />
                    <p className="panel-content-text-black" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(props.text)}}></p>
                    <div className="panel-content-image-contain" style={{backgroundImage: 'url('+props.image+')'}}></div>
                </div>
            </div>

            { /* LG, XL, XXL */ }
            <div className="col-6 d-none d-lg-block">
                <div className="panel-content-contain-desktop">
                    <ElementHeader text={props.title} />
                    <p className="panel-content-text-black" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(props.text)}}></p>
                    <div className="panel-content-image-contain" style={{backgroundImage: 'url('+props.image+')'}}></div>
                </div>
            </div>
        </React.Fragment>
    );
}

// Props: title, text, image
function ElementPanelContentContainIcon(props)
{
    return (
        <React.Fragment>
            { /* XS, SM */ }
            <div className="col-12 d-block d-md-none">
                <div className="panel-content-contain-mobile-icon">
                    <ElementHeader text={props.title} />
                    <p className="panel-content-text-black" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(props.text)}}></p>
                    <div className="panel-content-image-contain-icon" style={{backgroundImage: 'url('+props.image+')'}}></div>
                </div>
            </div>

            { /* MD */ }
            <div className="col-6 d-none d-md-block d-lg-none">
                <div className="panel-content-contain-tablet-icon">
                    <ElementHeader text={props.title} />
                    <p className="panel-content-text-black" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(props.text)}}></p>
                    <div className="panel-content-image-contain-icon" style={{backgroundImage: 'url('+props.image+')'}}></div>
                </div>
            </div>

            { /* LG, XL, XXL */ }
            <div className="col-6 d-none d-lg-block">
                <div className="panel-content-contain-desktop-icon">
                    <ElementHeader text={props.title} />
                    <p className="panel-content-text-black" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(props.text)}}></p>
                    <div className="panel-content-image-contain-icon" style={{backgroundImage: 'url('+props.image+')'}}></div>
                </div>
            </div>
        </React.Fragment>
    );
}

// Props: title, text
function ElementPanelHeader(props)
{
    return (
        <div className="col">
            <div className="panel-header">
                <ElementHeader text={props.title} />
                { props.text !== '' &&
                    <p className="panel-content-text-background" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(props.text)}}></p>
                }
            </div>
        </div>
    );
}

// Props: title, image
function ElementPanelLabel(props)
{
    return (
        <div className="col-6 col-md-3">
            <div className="panel-header">
                <div className="panel-content-label-title">{props.title}</div>
                
                { /* XS, SM, MD */ }
                <div className="d-block d-lg-none">
                    <div className="panel-content-label-logo-mobile" style={{backgroundImage: 'url('+props.image+')'}}></div>
                </div>

                { /* LG, XL, XXL */ }
                <div className="d-none d-lg-block">
                    <div className="panel-content-label-logo-desktop" style={{backgroundImage: 'url('+props.image+')'}}></div>
                </div>
            </div>
        </div>
    );
}

// Props: title, image
function ElementPanelPartner(props)
{
    return (
        <div className="col-4 col-sm-3 col-md-2 col-lg-1">
            <div className="panel-header">
                { /* XS, SM, MD */ }
                <div className="d-block d-lg-none">
                    <div className="panel-content-partner-logo-mobile" style={{backgroundImage: 'url('+props.image+')'}}></div>
                </div>

                { /* LG */ }
                <div className="d-none d-lg-block d-xl-none">
                    <div className="panel-content-partner-logo-tablet" style={{backgroundImage: 'url('+props.image+')'}}></div>
                </div>

                { /* XL, XXL */ }
                <div className="d-none d-xl-block">
                    <div className="panel-content-partner-logo-desktop" style={{backgroundImage: 'url('+props.image+')'}}></div>
                </div>
            </div>
        </div>
    );
}

// Props: options, translations
function ElementStatus(props)
{
    if (props.translations['status_outofoffice'] !== undefined && props.options !== undefined)
    {
        if (props.options[2] !== null)
        {
            return (
                <div className="status-section">
                    <div className="container-sm">
                        <div className="row">
                            <div className="col">
                                <div className="status-container">
                                    { props.translations['status_outofoffice'] !== undefined &&
                                        <div className="status-title">{props.translations['status_outofoffice'].replace('$date', props.options[2])}</div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }
    
    return null;
}

export { ElementBanner, ElementContentLink, ElementDebugResponsive, ElementFooterLink, ElementHeader, ElementHeaderLarge, ElementPanelContentContain, ElementPanelContentContainIcon, ElementPanelHeader, ElementPanelLabel, ElementPanelPartner, ElementStatus }