import React from 'react';

import { SectionBanner, SectionContact, SectionContent, SectionDisclaimer, SectionFooter, SectionPrivacy } from '../components/Section';
import { ElementDebugResponsive } from '../components/Element';

function PageDisclaimer(props)
{
    return (
        <React.Fragment>
            <SectionDisclaimer
                debug={props.debug}
                ip_address={props.ip_address}
                translations={props.translations} />

            <SectionFooter
                pages={props.pages}
                translations={props.translations} />

            { props.debug === true &&
                <ElementDebugResponsive />
            }
        </React.Fragment>
    );
}

function PageHomepage(props)
{
    return (
        <React.Fragment>
            <SectionBanner
                translations={props.translations} />

            <SectionContent
                debug={props.debug}
                ip_address={props.ip_address}
                translations={props.translations}
                upload_images={props.upload_images} />

            <SectionContact
                debug={props.debug}
                ip_address={props.ip_address}
                translations={props.translations} />
                
            <SectionFooter
                translations={props.translations} />

            { props.debug === true &&
                <ElementDebugResponsive />
            }
        </React.Fragment>
    );
}

function PagePrivacy(props)
{
    return (
        <React.Fragment>
            <SectionPrivacy
                debug={props.debug}
                ip_address={props.ip_address}
                translations={props.translations} />

            <SectionFooter
                pages={props.pages}
                translations={props.translations} />

            { props.debug === true &&
                <ElementDebugResponsive />
            }
        </React.Fragment>
    );
}

export { PageDisclaimer, PageHomepage, PagePrivacy };